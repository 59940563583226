import React, { useState, useRef  } from "react";
import { Element } from "react-scroll";
import "./Contact.css";
import Footer from "../Footer/Footer";
import firebase from "../../FirebaseConfig";
import myBild from "./moha.awad.jpg";
import { Box, Grid } from "@material-ui/core";
import emailjs from "emailjs-com";


function Contact() {
  const [isSending, setIsSending] = useState(false);
  const [isSent, setIsSent] = useState(false);
  const form = useRef();

  const contactForm = firebase.database().ref("contactMessages");

  const contactFormSubmit = (event) => {
    event.preventDefault();
    if (!document.getElementById("contact_form").checkValidity()) {
      return;
    }
    var name = getElementVal("nameField");
    var email = getElementVal("emailField");
    var msg = getElementVal("msgContact");

    saveMessages(name, email, msg);
    resetForm("contact_form");

    //Display
    setIsSending(true);
    setTimeout(() => {
      setIsSending(false);
      setIsSent(true);
      setTimeout(() => setIsSent(false), 2500);
    }, 2000);
  };

  const saveMessages = (name, email, msg) => {
    var newContactFormRef = contactForm.push();
    newContactFormRef.set({
      name: name,
      email: email,
      msg: msg,
    });
  };
  const getElementVal = (id) => {
    return document.getElementById(id).value;
  };
  const resetForm = (id) => {
    document.getElementById(id).reset();
  };

  const sendEmail = (e) => {
    e.preventDefault();

    emailjs.sendForm('service_l0ehoyr', 'template_rcb7vgt', form.current, 'LtNrC1KvZBgKskyxe')
      .then((result) => {
          console.log(result.text);
      }, (error) => {
          console.log(error.text);
      });
  };

  return (
    <Element id="contact">
      <Box className="row img_contact" data-aos="fade-up">
        <h3 className="col-12">Contact</h3>
        <strong className="col-12">Mohammad Awad</strong>
        <img
          src={myBild}
          width="230"
          height="400"
          alt="Mohammad Awad"
          className="moha_img"
        />

        {/* <Box className="lookingStrong col-12 mt-5">
          Looking för internship
        </Box> */}
        {/* <div className="hr_contact  mt-3">
          <hr></hr>
        </div> */}
        <div className="col-12">
          <Footer />
        </div>
      </Box>

      <Box
        class="container mt-5 col-10 col-sm-7 col-md-6 col-lg-5 col-xl-6"
        data-aos="fade-up"
      >
        <h3 class="text-center pt-4">Contact me</h3>
        <br />
        <div class="row">
          <div class="col-sm-8 col-md-10 m-5 ">
            <form
              id="contact_form"
              className="contact_form"
              onSubmit={contactFormSubmit}
              noValidate
              ref={form}

            >
              <label>Your name</label>
              <input class="form-control" name="name" id="nameField" required />
              <br />
              <label>Your email</label>
              <input
                class="form-control"
                type="email"
                name="email"
                id="emailField"
                required
              />
              <br />
              <label>Your message</label>
              <textarea
                id="msgContact"
                class="form-control"
                name="text"
                required
              ></textarea>
              <br />
              <div className="row d-flex justify-content-center align-content-center">
                <button
                  type="submit"
                  className={isSending || isSent ? "sending" : "noneBtn"}
                >
                  <span className="icon material-symbols-outlined">
                    {isSent ? "check" : "send"}
                  </span>
                  <span className="text">
                    {isSending
                      ? "Sending..."
                      : isSent
                      ? "Sent"
                      : "Send message"}
                  </span>
                </button>
              </div>
            </form>
          </div>
        </div>
      </Box>
    </Element>
  );
}

export default Contact;
