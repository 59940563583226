import "./Header.css";
import { useState, useEffect } from "react";
import { Link } from "react-scroll";
import "@theme-toggles/react/css/Classic.css";
import resume from "./resume.pdf";
import gokaya from "./gokaya.pdf";
import { Icon } from "@iconify/react";

import {
  Box,
  DialogTitle,
  Dialog,
  DialogContent,
  Button,
  makeStyles,
  Grid,
} from "@material-ui/core";
import { Switch } from "@mui/material";
import { RiSunFill, RiMoonFill } from "react-icons/ri";

const useStyles = makeStyles((theme) => ({
  customDialogMobil: {
    "& .MuiPaper-root": {
      backgroundColor: "black",
      maxWidth: "none",
      padding: "20px",
    },
  },
  a: {
    color: "white",
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  close: {
    display: "flex",
    justifyContent: "right",
    padding: 10,
  },
}));
export default function Header({ handleModeChange, whiteMode }) {
  const classes = useStyles();
  const [show, setShow] = useState(false);
  const [home, setHome] = useState(true);
  const [proj, setProj] = useState(false);
  const [contacts, setContact] = useState(false);
  const [showDataText, setShowDataText] = useState(false);
  const q = window.matchMedia("(max-width: 768px)").matches;
  const handleClickOpenText = () => {
    setShowDataText(true);
  };
  const handleClickCloseText = () => {
    setShowDataText(false);
  };
  useEffect(() => {
    window.addEventListener("scroll", () => {
      var scrolls = window.scrollY;

      if (q) {
        if (scrolls > 0 && scrolls < 300) {
          navhome();
        } else if (scrolls > 300 && scrolls < 1980) {
          navproj();
        } else if (scrolls > 1980 && scrolls < 3000) {
          navcontact();
        }
      } else {
        if (scrolls > 0 && scrolls < 300) {
          navhome();
        } else if (scrolls > 300 && scrolls < 1980) {
          navproj();
        } else if (scrolls > 1980 && scrolls < 3000) {
          navcontact();
        }
      }
    });
  }, [q]);
  function showbtn() {
    if (show) {
      setShow(false);
    } else {
      setShow(true);
    }
  }
  function navhome() {
    setHome(true);
    setProj(false);
    setContact(false);
    setShow(false);
  }
  function navproj() {
    setHome(false);
    setProj(true);
    setContact(false);
    setShow(false);
  }
  function navcontact() {
    setHome(false);
    setProj(false);
    setContact(true);
    setShow(false);
  }
  function navdocs() {
    setShow(false);
  }

  const sunOpacity = whiteMode ? 1 : 0.5;
  const moonOpacity = whiteMode ? 0.5 : 1;
  return (
    <div className="header">
      <div className="header_left">
        <h3>
          &lt; Moha<span style={{ color: "#4070F4" }}>mm</span>ad &gt;
          <p style={{ marginLeft: "2vh" }} className="fullstack">
            Fullstack developer
          </p>
        </h3>
      </div>

      <div
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <RiMoonFill style={{ opacity: moonOpacity, marginRight: "10px" }} />
        <Switch
          color="primary"
          checked={whiteMode}
          onChange={handleModeChange}
          sx={{
            "& .MuiSwitch-track": {
              backgroundColor: "#8b949e",
            },
            
          }}
        />
        <RiSunFill style={{ opacity: sunOpacity, marginLeft: "10px" }} />
      </div>

      <div className="header_right">
        <Link to="home" smooth={true.toString()} duration={900}>
          <h5 className={home ? "nav-active" : ""} onClick={() => navhome()}>
            Home
          </h5>
        </Link>
        <Link to="projects" smooth={true.toString()} duration={900}>
          <h5 className={proj ? "nav-active" : ""} onClick={() => navproj()}>
            Projects
          </h5>
        </Link>
        <Link to="contact" smooth={true.toString()} duration={900}>
          <h5
            className={contacts ? "nav-active" : ""}
            onClick={() => navcontact()}
          >
            Contact
          </h5>
        </Link>
        <h5 smooth={true.toString()} onClick={handleClickOpenText}>
          Docs
        </h5>{" "}
      </div>
      <div className="toggleButton">
        <button
          className="toggleButton_bg"
          style={{ outline: "none", border: "none", background: "transparent" }}
          onClick={showbtn}
        >
          {show ? (
            <i
              className="fa fa-times"
              style={{ fontSize: "25px", color: "red" }}
            ></i>
          ) : (
            <i
              className="fa fa-bars"
              style={{ fontSize: "25px", color: "#4070F4" }}
            ></i>
          )}
        </button>
      </div>
      <div className={show ? "show" : "mobile_view"}>
        <Link to="home" smooth={true.toString()} duration={900}>
          <h5 className={home ? "nav-active" : ""} onClick={() => navhome()}>
            Home
          </h5>
        </Link>
        <Link to="projects" smooth={true.toString()} duration={900}>
          <h5 className={proj ? "nav-active" : ""} onClick={() => navproj()}>
            Projects
          </h5>
        </Link>
        <Link to="contact" smooth={true.toString()} duration={900}>
          <h5
            className={contacts ? "nav-active" : ""}
            onClick={() => navcontact()}
          >
            Contact
          </h5>
        </Link>
        <div onClick={navdocs}>
          <h5 onClick={handleClickOpenText}>Docs</h5>
        </div>
        {showDataText && (
          <Box>
            <Dialog
              open={showDataText}
              onClose={handleClickCloseText}
              className={classes.customDialogMobil}
            >
              <Box className={classes.close}>
                <i
                  onClick={handleClickCloseText}
                  className="fa fa-times"
                  style={{ fontSize: "25px", color: "red" }}
                ></i>
              </Box>
              <Grid container spacing={3}>
                {/* <Grid item sm={12} md={6}>
                  <Box>
                    <DialogTitle>CV & personal letter</DialogTitle>
                    <DialogContent className={classes.mt4}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#4070F4" }}
                      >
                        {" "}
                        <a
                          href={resume}
                          download="Mohammad awad"
                          target="_blank"
                          className={classes.a}
                        >
                          Download
                          <Icon
                            className="cloud-icon"
                            icon="material-symbols:sim-card-download-outline-rounded"
                            width="30"
                            height="30"
                            color="white"
                          ></Icon>
                        </a>
                      </Button>
                    </DialogContent>
                  </Box>
                </Grid> */}
                <Grid item sm={12} md={12}>
                  <Box>
                    <DialogTitle> Internship Review</DialogTitle>
                    {/* <DialogContent>
                      Here is my LIA certification with Gokaya
                    </DialogContent> */}

                    <DialogContent className={classes.mt4}>
                      <Button
                        variant="contained"
                        style={{ backgroundColor: "#4070F4" }}
                      >
                        <a
                          href={gokaya}
                          download="Internship Review"
                          target="_blank"
                          className={classes.a}
                        >
                          {" "}
                          Download
                          <Icon
                            className="cloud-icon"
                            icon="material-symbols:sim-card-download-outline-rounded"
                            width="30"
                            height="30"
                            color="white"
                          ></Icon>
                        </a>
                      </Button>
                    </DialogContent>
                  </Box>
                </Grid>
              </Grid>
            </Dialog>
          </Box>
        )}
      </div>
    </div>
  );
}
