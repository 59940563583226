import "./Home.css";
import animationData from "../../assets/dev.json";
import Lottie from "react-lottie";
import { Box } from "@material-ui/core";

export const Home = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: "xMidYMid slice",
    },
  };

  return (
    <div id="home">
      <section className=" row container-about" style={{marginTop: '4rem'}}>
        <div className="col-12 col-sm-10 col-md-7 col-lg-8 col-xl-8">
          {/* <span className="purple-text">✋Hello. </span> */}
          <span className="hello">✋Hello, </span>
          {/* <div className="myText1">
              I am Moha, partly Palistinian, partly Swedish.
            </div> */}
          <Box className="myText2">
            I am Moha, living in sunny south Sweden, near the nature and the
            wonderful west coast. I enjoy the peace and harmony out here, it
            gives me a lof of energy and creativity to amplify my ideas. With
            one foot in the warm and spicy middle east, and the other in the
            landet,{" "}
            <a
              className="lagom"
              title="Lagom is a swedish word meaning 'just the right amount'"
              href="https://en.wikipedia.org/wiki/Lagom"
            >
              Lagom.
            </a>
            &nbsp;My mission is to bring something new to the table.
          </Box>
          <Box mt='2rem'>
            <hr
              className="hr"
              style={{ width: "15%", textAlign: "left", marginLeft: 0 }}
            ></hr>
            <strong>Working with:</strong>
            <p>
              HTML5, CSS3, JavaScript, TS, React.js & React.Native & Redux,
              Next.js,{" "}
              <p>
                SQL, PgAdmin, Docker, Node.js, Github & GitLab, and good skills
                in C#
              </p>
            </p>
          </Box>
        </div>
        <Box className="col-12 col-sm-10 col-md-8 col-lg-9 col-xl-2">
          <div className="img-fluid" data-aos="fade-up">
            <Lottie options={defaultOptions} />
          </div>
        </Box>
        {/* <button className="download-btn col-10 col-sm-6 col-md-12 col-lg-4 col-xl-3">
          <a href={resume} download="resume" target="_blank">
            <span className="span">Download CV</span>
            <Icon
              className="cloud-icon"
              icon="material-symbols:sim-card-download-outline-rounded"
              width="40"
              height="40"
            ></Icon>
          </a>
        </button> */}

        {/* <div className="download col-12 col-sm-12 col-md-12 col-lg-12 col-xl-12">
          <a href={resume} download="resume" target="_blank">
            <button>Download cv</button>
          </a>
        </div> */}
      </section>
    </div>
  );
};
