import React, { useEffect, useState } from "react";
import {
  Dialog,
  makeStyles,
  CardActions,
  CardContent,
  Typography,
  Box,
  styled,
} from "@material-ui/core";
import ProjectView from "./ProjectView";
import { motion } from "framer-motion";

const useStyles = makeStyles((theme) => ({
  customDialogMobil: {
    "& .MuiPaper-root": {
      backgroundColor: "black",
      maxWidth: "500px",
      width: "100%",
      padding: "20px",
    },
  },
  a: {
    color: "white",
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  linkBubble: {
    width: "10rem",
    height: "10rem",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    fontFamily: "$text", // Jag har lagt till detta, men du måste definiera "$text" någonstans
    fontWeight: "500",
    fontSize: "1.4rem",
    textAlign: "center",
    position: "absolute",
    background: "#F1F1F1",
    color: "#000",
    borderRadius: "100%",
    zIndex: 1000,
    right: "0rem",
    top: "-4.5rem",
    cursor: "pointer",

    "&:hover": {
      background: "#CCCCCC",
    },

    // Mediefråga för responsiv design vid bp1
    [theme.breakpoints.up("bp1")]: {
      width: "14.8rem",
      height: "14.8rem",
      right: "10rem",
      top: "-7.4rem",
      fontSize: "3rem",
    },
  },
}));
const ProjectUI = (props) => {
  const classes = useStyles();
  const [showDataText, setShowDataText] = useState(false);

  const [view, setView] = useState(false);
  const [title, setTitle] = useState("");
  const [desc, setDesc] = useState("");
  const [lang, setLang] = useState("");
  const [github, setGithub] = useState("");
  const [path, setPath] = useState("");
  const [list, setList] = useState([]);
  const [webLink, setWebLink] = useState("");

  useEffect(() => {
    setShowDataText(view); // Uppdatera showDataText när view ändras
  }, [view]);

  function setData(title, lang, github, desc, path, list, webLink) {
    setTitle(title);
    setLang(lang);
    setGithub(github);
    setDesc(desc);
    setPath(path);
    setList(list);
    setWebLink(webLink);
    setView(!view); // Uppdatera view för att öppna dialogrutan
  }
  function handleChange() {
    setView(!view);
  }

  const handleClickOpenText = () => {
    setShowDataText(!showDataText);
  };
  const handleClickCloseText = () => {
    setShowDataText(false);
  };
  return (
    <>
      <Dialog
        open={showDataText}
        onClose={handleClickCloseText}
        className={classes.customDialogMobil}
      >
        {view ? (
          <ProjectView
            display={view}
            handleChange={() => setView(false)} // Stäng dialogrutan när det är dags
            title={title}
            lang={lang}
            github={github}
            desc={desc}
            path={path}
            list={list}
            webLink={webLink}
            handleClickCloseText={handleClickCloseText}
          />
        ) : (
          ""
        )}
      </Dialog>
      <div
        data-aos="fade-up"
        class="project_width"
        onClick={() =>
          setData(
            props.title,
            props.lang,
            props.github,
            props.desc,
            props.path,
            props.list,
            props.webLink
          )
        }
      >
        <div className="my-grid-container" onClick={handleClickOpenText}>
          <CardActions className="project_box">
            <Box
              className="project_img"
              style={{
                height: "350px",
                cursor: "pointer",
                position: "relative",
              }}
            >
              <img
                src={props.img}
                alt={props.title}
                style={{ objectFit: "cover", height: "100%", width: "100%" }}
              />
              {props.webLink && ( // Villkorlig rendering
                <a
                  className={classes.linkBubble}
                  transition={{ type: "spring", bounce: 0.5, delay: 1.7 }}
                  target="__blank"
                  href={props.webLink}
                >
                  Visit Website
                </a>
              )}
            </Box>
          </CardActions>
          <CardContent>
            <Typography className="project_title" variant="h5">
              {props.title}
            </Typography>
          </CardContent>
        </div>
      </div>
    </>
  );
};

export default ProjectUI;
