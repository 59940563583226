const importAll = (r) => r.keys().map(r);
const imagesReactNativ = importAll(
  require.context(
    "../../assets/Projects/mobil/examenarbete",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const imagesTodos = importAll(
  require.context(
    "../../assets/Projects/mobil/Todo_List",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const imagesInstagram = importAll(
  require.context(
    "../../assets/Projects/mobil/Instagram",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const MobileProjects = [
  {
    title: "React.Native app",
    img: imagesReactNativ[0],
    desc: "Graduation work. The purpose of this work was to investigate how react.native can be used to create a user-friendly and simple app to facilitate freelancers and workers to sell or exchange services. The end product has a complete functionality with a database and an interface.",
    lang: "Javascript, React.Native, StyleSheet, Firebase.",
    github: "https://github.com/awmoha/Examenarbete",
    path: "../../assets/Projects/mobil/Todo_List",
    list: imagesReactNativ,
  },
  {
    title: "Todo List",
    img: imagesTodos[2],
    desc: "Todo List",
    lang: "HTML, CSS, Javascript.",
    github: "https://github.com/awmoha/To-do-list",

    path: "../../assets/Projects/mobil/Todo_List",
    list: imagesTodos,
  },
  {
    title: "Instagram clone",
    img: imagesInstagram[0],
    desc: "tried to build similar instagram.",
    lang: "HTML, Css, Javascript, React, Redux.",
    github: "",
    path: "../../assets/Projects/mobil/Instagram",
    list: imagesInstagram,
  },
];

export default MobileProjects;
