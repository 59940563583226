import Firebase from "firebase/compat/app"
import "firebase/compat/database"

const firebaseConfig = {
  apiKey: "AIzaSyAMPPYy5gejbYRT7xo7HJxrhLjcLNjOqTQ",
  authDomain: "myporfolio-f500c.firebaseapp.com",
  databaseURL: "https://myporfolio-f500c-default-rtdb.firebaseio.com",
  projectId: "myporfolio-f500c",
  storageBucket: "myporfolio-f500c.appspot.com",
  messagingSenderId: "860148819416",
  appId: "1:860148819416:web:191231cde43ebd6f8cfead",
  measurementId: "G-CK5PJE9NET"
};
Firebase.initializeApp(firebaseConfig);
export default Firebase;