import { useState, useEffect } from "react";
import "./index.css";
import Header from "./compoents/Header/Header";
import { Home } from "./compoents/Home/Home";
import Projects from "./compoents/Projects/Projects";
import Contact from "./compoents/Contact/Contact";
import SimpleReactLightbox from "simple-react-lightbox";
import AOS from "aos";
import "aos/dist/aos.css";

function App() {
  const [whiteMode, setWhitetMode] = useState(false);

  const handleModeChange = () => {
    setWhitetMode(!whiteMode);
    document.body.classList.toggle("dark");
  };

  const [loading, isLoading] = useState(true);
  useEffect(() => {
    isLoading(false);
    AOS.init({
      offset: 200,
      duration: 500,
    });
  }, []);

  return loading ? (
    <>
      <div class="loader-wrapper">
        <span class="loader">
          <span class="loader-inner"></span>
        </span>
      </div>
      <div class="area">
        <ul class="circles">
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
          <li
            style={{ background: "rgba(76, 117, 242, 0.13174509803921569)" }}
          ></li>
        </ul>
      </div>
    </>
  ) : (
    <SimpleReactLightbox>
      <div className="App">
        <div class="area">
          <ul class="circles">
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
            <li></li>
          </ul>
        </div>
        <div class={whiteMode ? "white-mode" : "dark-mode"}>
          <Header whiteMode={whiteMode} handleModeChange={handleModeChange} />
          <Home />
          <Projects />
          <br></br>
          <br></br>

          <Contact />
        </div>
      </div>
    </SimpleReactLightbox>
  );
}

export default App;
