import React, { useState } from "react";
import { Link, Element } from "react-scroll";
import WebProjects from "./WebProjects";
import MobileProjects from "./MobileProjects";
import ProjectUI from "./ProjectUI";
import "./Projects.css";

const Projects = () => {
  const [choosen, setChoosen] = useState(true);

  return (
    <Element className="projects" >
      <br />
      <br />
      <br />
      <section class="row container-about ">
     <div className="text_title_Projects">
        <h1 className="h1_Project" data-aos="fade-up" data-aos-delay="50">
          Projects
        </h1>
    <small>Collection of selected work from my studies:</small>
    </div>
        <div className="project_button">
          <Link to="project_scroll" smooth={true.toString()} duration={500}>
            <h4
              className={
                choosen ? "projectContainer__title--active" : undefined
                
              }
              onClick={() => setChoosen(true)}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Web 
            </h4>
          </Link>
          <Link to="project_scroll" smooth={true.toString()} duration={500}>
            <h4
              className={
                !choosen ? "projectContainer__title--active" : undefined
              }
              onClick={() => setChoosen(false)}
              data-aos="fade-up"
              data-aos-delay="200"
            >
              Mobile 
            </h4>
          </Link>
        </div>
      </section>

      <div className="projects_container" >
        {choosen
          ? WebProjects.map((project, index) => (
              <ProjectUI
                title={project.title }
                img={project.img}
                desc={project.desc}
                path={project.path}
                lang={project.lang}
                github={project.github}
                list={project.list}
                webLink={project.webLink}
                key={index}
              />
            ))
          : MobileProjects.map((project, index) => (
              <ProjectUI
                title={project.title}
                img={project.img}
                desc={project.desc}
                path={project.path}
                lang={project.lang}
                github={project.github}
                list={project.list}
                webLink={project.webLink}
                key={index}
              />
            ))}
      </div>
    </Element>
  );
};

export default Projects;
