import React from "react";
import "./Footer.css";
import gmail from "../../assets/social/gmail.svg";
import instagram from "../../assets/social/instagram.svg";
import github from "../../assets/social/github.svg";
import linkedin from "../../assets/social/linkedin.svg";
function Footer() {
  return (

<div className="footer">
      <div className="social-media">
        <a
          classsName="socia-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="mailto:mohammad.awad0110@gmail.com"
        >
          <img src={gmail} alt="" />
        </a>
        <a
          classsName="socia-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.linkedin.com/in/mohammad-awad-03b619201/"
        >
          <img src={linkedin} alt="" />
        </a>
        <a
          classsName="socia-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="https://github.com/awmoha"
        >
          <img src={github} alt="" />
        </a>
        
        <a
          classsName="socia-icon"
          target="_blank"
          rel="noopener noreferrer"
          href="https://www.instagram.com/moha.3wad/"
        >
          <img src={instagram} alt="" />
        </a>
      </div>
      </div>
        )
}

export default Footer