import React, { useState } from "react";
import {
  DialogContent,
  makeStyles,
  Grid,
  Box,
  Typography,
} from "@material-ui/core";
import { SRLWrapper } from "simple-react-lightbox";
import "./ProjectView.css";
const useStyles = makeStyles((theme) => ({
  customDialogMobil: {
    "& .MuiPaper-root": {
      backgroundColor: "black",
      maxWidth: "none",
      padding: "20px",
    },
  },
  a: {
    color: "white",
  },
  mt4: {
    marginTop: theme.spacing(4),
  },
  mt2: {
    marginTop: theme.spacing(2),
  },
  close: {
    display: "flex",
    justifyContent: "right",
    padding: 10,
  },
}));
function ProjectView(props, handleClickCloseText) {
  const classes = useStyles();
  const [chosen, setChosen] = useState(true);

  window.onclick = function (event) {
    const modal = document.getElementById("select");
    if (event.target === modal) {
      props.handleChange();
    }
  };

  const options = {
    buttons: {
      backgroundColor: "rgb(88 73 71 / 80%)",
      iconColor: "rgb(255 255 255 / 70%)",
      showAutoplayButton: true,
      showCloseButton: true,
      showDownloadButton: false,
      showFullscreenButton: true,
      showNextButton: true,
      showPrevButton: true,
      showThumbnailsButton: true,
    },
    settings: {
      overlayColor: "rgb(81 76 73)",
      transitionSpeed: 1000,
      transitionTimingFunction: "linear",
    },
    thumbnails: {
      thumbnailsSize: ["120px", "100px"],
      thumbnailsOpacity: 0.4,
    },
    caption: {
      captionColor: "rgba(241, 191, 152, 1)",
    },
    progressBar: {
      size: "4px",
      backgroundColor: "rgba(255, 237, 225, 1)",
      fillColor: "#AF9AB2",
    },
  };
  return (
    <div
      className={props.display ? "project_view" : "project_views_close"}
      id="select"
    >
      <div className="project_view_container">
        <DialogContent>
          <Box>
            <Box item xs={4} className={classes.close}>
              <div
                className="project_view_close"
                onClick={props.handleClickCloseText}
              >
                <i
                  className="fa fa-times"
                  style={{ fontSize: "25px", color: "red" }}
                ></i>
              </div>
            </Box>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <div className="project_view_button">
                  <Typography
                    variant="h5"
                    onClick={() => setChosen(true)}
                    className={chosen ? "project_view_button_active" : ""}
                  >
                    Overview
                  </Typography>
                  <Typography
                    variant="h5"
                    className={chosen ? "" : "project_view_button_active"}
                    onClick={() => setChosen(false)}
                  >
                    Screenshot
                  </Typography>
                </div>
              </Grid>
            </Grid>
          </Box>
        </DialogContent>

        <div className="project_view_body">
          <DialogContent>
            {chosen ? (
              <Box>
                <Grid container spacing={2}>
                  <Grid item sm={12} md={6}>
                    <div className="project_view_lang">
                      <strong>Code: </strong>
                      <p>{props.lang}</p>
                    </div>
                  </Grid>
                  <Grid item sm={12} md={6}>
                    <div className="project_view_github">
                      <strong>Github: </strong>
                      {props.github !== "" ? (
                        <a
                          href={props.github}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          {props.github}
                        </a>
                      ) : (
                        <p>Confidentiality agreement</p>
                      )}
                    </div>
                  </Grid>

                  <Grid item sm={12} md={12}>
                    <div className="project_view_desc">
                      <strong>Description:</strong>
                      <p> {props.desc} </p>
                    </div>
                  </Grid>
                </Grid>
              </Box>
            ) : (
              <SRLWrapper options={options}>
                <div className="project_view_screenshot" id="style-5">
                  {props.list.map((val, index) => (
                    <div className="screenshot_container" key={index}>
                      <a href={val}>
                        <img src={val} alt="" />
                      </a>
                    </div>
                  ))}
                </div>
              </SRLWrapper>
            )}
          </DialogContent>
        </div>
      </div>
    </div>
  );
}

export default ProjectView;
