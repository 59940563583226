const importAll = (r) => r.keys().map(r);
const imagesSmartRefill = importAll(
  require.context(
    "../../assets/Projects/web/smartRefill",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const imagesGokaya = importAll(
  require.context(
    "../../assets/Projects/web/GoKaya",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const imagesCMS = importAll(
  require.context(
    "../../assets/Projects/web/Cms-Strapi",
    false,
    /\.(png|jpe?g|svg)$/
  )
);
const imagesHighScore = importAll(
  require.context(
    "../../assets/Projects/web/Highscores",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const imagesNikeWebsite = importAll(
  require.context(
    "../../assets/Projects/web/anotherNike_website",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const imagesSchoolApp = importAll(
  require.context(
    "../../assets/Projects/web/School_Project",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const imagesQuiz = importAll(
  require.context("../../assets/Projects/web/Quiz", false, /\.(png|jpe?g|svg)$/)
);
const imagesBankCard = importAll(
  require.context(
    "../../assets/Projects/web/Bank_Card",
    false,
    /\.(png|jpe?g|svg)$/
  )
);

const WebProjects = [
  {
    title: "Internship 2 'SmartRefill AB'",
    img: imagesSmartRefill[39],
    desc: "Create a new frontend application that simplifies the lives of customers and in-house sales staff by allowing them to generate reports based on Refill data.This application can be integrated with our intern application and enable users to access various reports, from basic annual sales reports to complex filtered reports based on multiple variables.",
    lang: "React, TypeScript, Vite.js, Emotion.js, Chakra UI, Redux, Redux Saga, REST API, Figma, Chart.js, react-select-event, testing: vite.test,  jest and React-testing-library.",
    github: "",
    path: "../../assets/Projects/web/smartRefill",
    list: imagesSmartRefill,
  },
  {
    title: (
      <>
        Internship 1 'RentalBuddy.io' <span style={{ fontSize: '10px' }}>Former: Gokaya AB</span>
      </>
    ),    img: imagesGokaya[0],
    desc: " During my internship I worked with the latest functionality and technology in TypeScript, Sass and JavaScript. My responsibilities have been varied, from developing and designing functionality in several projects, to being a coach to the new students who did their LIA at Gokaya.",
    lang: "TypeScript, JavaScript (React), MUI, CSS, Firebase.",
    github: "",
    path: "../../assets/Projects/web/fetch_react",
    list: imagesGokaya,
    webLink: "https://www.rentalbuddy.io/",
  },

  {
    title: "CMS Strapi, e-commerce",
    img: imagesCMS[1],
    desc: "I created a ToolShop page using a Headless CMS, specifically Strapi. To enhance the functionality of the page, I utilized the email plugin in Strapi. This allows me to receive an email notification every time a new product is created on the page. This feature makes it easy for me to stay updated on new additions to the ToolShop inventory.",
    lang: "React, Node.js, MUI.",
    github: "https://github.com/awmoha/Cms-Strapi",
    path: "../../assets/Projects/web/Highscores",
    list: imagesCMS,
  },
  
  {
    title: "Highscores",
    img: imagesHighScore[0],
    desc: "I have developed a highscore app that allows users to create new games and register their high scores.",
    lang: "Bootstrap, Node.js ,Express.js, PgAdmin, Docker. ",
    github: "https://github.com/awmoha/Highscores-node.js-and-react",
    path: "../../assets/Projects/web/Highscores",
    list: imagesHighScore,
  },

  {
    title: "School application",
    img: imagesSchoolApp[0],
    desc: "I created a post-secondary education application that streamlines the process of matching students with the appropriate schools. It includes a database for managing student applications and a matching algorithm to ensure the best fit for each student.",
    lang: "HTML, CSS, JavaScript, Redux, API.",
    github: "https://github.com/awmoha/Projekt-arbete--school-applikation-",
    path: "../../assets/Projects/web/School_Project",
    list: imagesSchoolApp,
  },

  {
    title: "Online quiz",
    img: imagesQuiz[0],
    desc: "I developed an interactive quiz application that poses 7 questions to the user and provides personalized feedback on the results at the end of the quiz. Users can test their knowledge and learn more about the topic of the quiz.",
    lang: "HTML, CSS, Bootstrap, JavaScript, Redux.",
    github: "https://github.com/awmoha/Quiz-",
    path: "../../assets/Projects/web/Quiz",
    list: imagesQuiz,
    webLink: "https://awmoha.github.io/Quiz-/",
  },
  {
    title: "Bank Card",
    img: imagesBankCard[6],
    desc: "Created an application where the user can handle different payment cards. The user must be able to have up to a maximum of 4 cards.",
    lang: "HTML, CSS, JavaScript, React, Redux.",
    github: "https://github.com/awmoha/Bank_Card",
    path: "../../assets/Projects/web/Bank_Card",
    list: [imagesBankCard[7]],
    webLink: "https://bankcardreact.web.app/",
  },
];

export default WebProjects;
